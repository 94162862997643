const rules = {
  name: [
    { required: true, max: 100 },
    {
      message: "Enter valid Person / Company Name",
      pattern: new RegExp("^[\x00-\x7F]*$"),
    },
  ],
  address: [
    { required: true, min: 10, max: 200 },
    { message: "Enter valid Address", pattern: new RegExp("^[\x00-\x7F]*$") },
  ],
  city: [
    { required: true, max: 50 },
    { message: "Enter valid Address", pattern: new RegExp("^[\x00-\x7F]*$") },
  ],
  phone: [
    { required: true },
    {
      message: "Enter valid 10 digit Mobile No.",
      pattern: new RegExp("^[0-9]{10}$"),
    },
  ],
  pincode: [
    { required: true },
    {
      message: "Enter valid 6 digit Pincode code",
      pattern: new RegExp("^[0-9]{6}$"),
    },
  ],
  email: [
    { max: 100 },
    {
      type: "email",
      message: "Enter a validate email",
    },
  ],
  width: [
    { required: true, max: 50 },
    {
      message: "Enter width 0.5(cm) or more",
      pattern: new RegExp(
        "^([0-9]+|[0-9]+[0-9].[0-9]+|[1-9].[0-9]+|0.[5-9][0-9]*)$"
      ),
    },
  ],
  height: [
    { required: true, max: 50 },
    {
      message: "Enter height 0.5(cm) or more",
      pattern: new RegExp(
        "^([0-9]+|[0-9]+[0-9].[0-9]+|[1-9].[0-9]+|0.[5-9][0-9]*)$"
      ),
    },
  ],
  length: [
    { required: true, max: 50 },
    {
      message: "Enter length 0.5(cm) or more",
      pattern: new RegExp(
        "^([0-9]+|[0-9]+[0-9].[0-9]+|[1-9].[0-9]+|0.[5-9][0-9]*)$"
      ),
    },
  ],
  weight: [
    { required: true, max: 5 },
    {
      message: "Enter weight 0.5(kg) or more",
      pattern: new RegExp(
        "^([0-9]+|[0-9]+[0-9].[0-9]+|[1-9].[0-9]+|0.[5-9][0-9]*)$"
      ),
    },
    //{ message: 'Enter weight 0.1(kg) or more', pattern: new RegExp("^(0*[1-9][0-9]*(.[0-9]+)?|0+.[0-9]*[1-9][0-9]*)$") }
  ],
  itemName: [
    { required: true, max: 100 },
    { message: "Enter valid Item Name", pattern: new RegExp("^[\x00-\x7F]*$") },
  ],
  quantity: [
    { required: true },
    {
      message: "Enter quantity 1 or more",
      pattern: new RegExp("^[1-9][0-9]*$"),
    },
  ],
  cost: [
    { required: true },
    {
      message: "Enter cost 1 or more",
      pattern: new RegExp("^(0*[1-9][0-9]*(.[0-9]+)?|0+.[0-9]*[1-9][0-9]*)$"),
    },
  ],
  cod_amount: [
    { required: true },
    {
      message: "Enter Amount 1 or more",
      pattern: new RegExp("^(0*[1-9][0-9]*(.[0-9]+)?|0+.[0-9]*[1-9][0-9]*)$"),
    },
  ],
  invoice_value: [
    { required: true },
    {
      message: "Enter Amount 1 or more",
      pattern: new RegExp("^(0*[1-9][0-9]*(.[0-9]+)?|0+.[0-9]*[1-9][0-9]*)$"),
    },
  ],
};

export default rules;
