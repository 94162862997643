import { all } from 'redux-saga/effects';
import Auth from './Auth';
import Orders from './Orders';
import Transactions from './Transactions';

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Orders(),
    Transactions()
  ]);
}
