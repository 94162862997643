import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT,
	SIGNUP,
	USER_DATA,
	//CHANGE_PASSWORD
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess
} from "../actions/Auth";

//import FirebaseService from 'services/FirebaseService'
import JwtAuthService from 'services/JwtAuthService'

export function* signIn() {
	yield takeEvery(SIGNIN, function* ({ payload }) {
		const { email, password, recaptcha } = payload;
		try {
			const response = yield call(JwtAuthService.signInEmailRequest, { email, password, recaptcha });
			if (response.isError) {
				yield put(showAuthMessage(response.message || 'Something went wrong'));
			} else {
				if (response.data.address && response.data.address.length) {
					let pickupAddress = response.data.address.find(o => o.address_type === 'pickup');
					if (pickupAddress && pickupAddress._id) {
						response.data.pickup_address_id = pickupAddress._id;
					}
				}
				localStorage.setItem(AUTH_TOKEN, response.data.token);
				localStorage.setItem(USER_DATA, JSON.stringify(response.data));
				yield put(authenticated(response.data.token, response.data));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signOut() {
	yield takeEvery(SIGNOUT, function* ({ payload }) {
		const { userId } = payload;
		try {
			if (/* signOutUser === undefined ||  */true) {
				localStorage.removeItem(AUTH_TOKEN);
				localStorage.removeItem(USER_DATA);
				yield put(signOutSuccess(/* signOutUser */));
				yield call(JwtAuthService.signOutRequest, { userId });
			} else {
				yield put(showAuthMessage(/* signOutUser.message */));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signUp() {
	yield takeEvery(SIGNUP, function* ({ payload }) {
		const { email, name, password, phone, store_name, recaptcha } = payload;
		try {
			const response = yield call(JwtAuthService.signUpEmailRequest, { email, name, password, phone, store_name, termsandcondition: true, recaptcha });
			if (response.isError) {
				yield put(showAuthMessage(response.message || 'Something went wrong'));
			} else {
				window.gtag && window.gtag("event", "SP-Submit", {
					event_category: "SP-Submit",
					event_label: "Click",
					value: "Click"
				})
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	}
	);
}

export function* verifyEmail() {
	yield takeEvery(SIGNUP, function* ({ payload }) {
		const { email, name, password, phone, store_name, recaptcha } = payload;
		try {
			const response = yield call(JwtAuthService.signUpEmailRequest, { email, name, password, phone, store_name, termsandcondition: true, recaptcha });
			if (response.isError) {
				yield put(showAuthMessage(response.message || 'Something went wrong'));
			} else {				
				localStorage.setItem(AUTH_TOKEN, response.data.token);
				localStorage.setItem(USER_DATA, JSON.stringify(response.data));
				yield put(authenticated(response.data.token, response.data));
				window.gtag && window.gtag("event", "SP-Submit", {
					event_category: "SP-Submit",
					event_label: "Click",
					value: "Click"
				})
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	}
	);
}

/* export function* changePassword() {
	yield takeEvery(CHANGE_PASSWORD, function* ({ payload }) {
		const { old_password, new_password, recaptcha } = payload;
		try {
			const user = yield call(JwtAuthService.changePasswordRequest, { old_password, new_password, recaptcha });
			if (user.isError) {
				yield put(showAuthMessage(user.message || 'Something went wrong'));
			} else {
				yield put(showAuthMessage(user.data.message || 'Your password has been changed.'));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	}
	);
} */

export default function* rootSaga() {
	yield all([
		fork(signIn),
		fork(signOut),
		fork(signUp),
		//fork(changePassword)
	]);
}