import fetch from 'auth/FetchInterceptor'

const TransactionsService = {}

TransactionsService.list = function (data) {
  return fetch({
    url: '/user/transactions',
    method: 'post',
    data: data
  })
}

export default TransactionsService