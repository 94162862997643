const dev = {
  API_ENDPOINT_URL: 'http://localhost:7001/api/v1',
  WEB_APP_URL: 'http://localhost:7000',
  WEB_SITE_URL: 'https://parceldeck.in'
};

const prod = {
  API_ENDPOINT_URL: 'https://webapi.parceldeck.in/api/v1',
  WEB_APP_URL: 'https://web.parceldeck.in',
  WEB_SITE_URL: 'https://parceldeck.in'
};

const test = {
  API_ENDPOINT_URL: 'https://webapi-dev.parceldeck.in/api/v1',
  WEB_APP_URL: 'https://web-dev.parceldeck.in',
  WEB_SITE_URL: 'https://parceldeck.in'
};

const getEnv = () => {
	switch (process.env.REACT_APP_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
