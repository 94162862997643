import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	FETCH_ORDERS_LIST
} from '../constants/Orders';
import {
	ordersList
} from "../actions/Orders";
import {
	showAuthMessage
} from "../actions/Auth";
import OrdersService from 'services/OrdersService'

export function* fetchOrdersList() {
	yield takeEvery(FETCH_ORDERS_LIST, function* () {
		try {
			const response = yield call(OrdersService.ordersList);
			if (response.isError) {
				yield put(showAuthMessage(response.message || 'Something went wrong'));
			} else {
				yield put(ordersList(response.data));
			}
		} catch (err) {
			//yield put(showAuthMessage(err));
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(fetchOrdersList),
	]);
}