import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`./dashboard`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/orders`}
          component={lazy(() => import(`./orders`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/transactions`}
          component={lazy(() => import(`./transactions`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/discrepancy`}
          component={lazy(() => import(`./weightDiscrepancy`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/setting`}
          component={lazy(() => import(`./pages/setting`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/ndr`}
          component={lazy(() => import(`./ndr`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/channel-integration`}
          component={lazy(() => import(`./channelIntegration`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/cod-remittance`}
          component={lazy(() => import(`./codRemittance`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support`}
          component={lazy(() => import(`./support`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboard`}
        />
        {/* <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
        <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
        <Route path={`${APP_PREFIX_PATH}/components`} component={lazy(() => import(`./components`))} />
        <Route path={`${APP_PREFIX_PATH}/pages`} component={lazy(() => import(`./pages`))} />
        <Route path={`${APP_PREFIX_PATH}/maps`} component={lazy(() => import(`./maps`))} />
        <Route path={`${APP_PREFIX_PATH}/charts`} component={lazy(() => import(`./charts`))} />
        <Route path={`${APP_PREFIX_PATH}/docs`} component={lazy(() => import(`./docs`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} /> */}
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
