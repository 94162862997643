import fetch from 'auth/FetchInterceptor'

const JwtAuthService = {}

JwtAuthService.signInEmailRequest = function (data) {
  return fetch({
    url: '/user/login',
    method: 'post',
    headers: {
      'public-request': 'true'
    },
    data: data
  })
}

JwtAuthService.signUpEmailRequest = function (data) {
  return fetch({
    url: '/user/signup',
    method: 'post',
    headers: {
      'public-request': 'true'
    },
    data: data
  })
}

JwtAuthService.verifyEmailRequest = function (data) {
  return fetch({
    url: '/user/verify/email',
    method: 'post',
    headers: {
      'public-request': 'true'
    },
    data: data
  })
}

JwtAuthService.me = function (data) {
  return fetch({
    url: '/user/me',
    method: 'get',
    headers: {
      'public-request': 'true',
      authorization: 'JWT ' + data.token
    }
  })
}

JwtAuthService.signOutRequest = function (data) {
  return fetch({
    url: '/user/logout',
    method: 'post',
    headers: {
      'public-request': 'true'
    },
    data: data
  })
}

JwtAuthService.changePasswordRequest = function (data) {
  return fetch({
    url: '/user/change-password',
    method: 'post',
    headers: {
      'public-request': 'true'
    },
    data: data
  })
}

JwtAuthService.socialSignInRequest = function (data) {
  return fetch({
    url: '/user/social-login',
    method: 'post',
    headers: {
    'public-request': 'true'
  },
    data: data
  })
}

/*JwtAuthService.signInFacebookRequest = function (data) {
  return fetch({
    url: '/signup',
    method: 'post',
    headers: {
    'public-request': 'true'
  },
    data: data
  })
} */

export default JwtAuthService