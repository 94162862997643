import fetch from "auth/FetchInterceptor";

const OrdersService = {};

OrdersService.list = function (data) {
  return fetch({
    url: "/shipping/orders/v2",
    method: "post",
    data: data,
  });
};

OrdersService.price = function (orderId) {
  return fetch({
    url: "/order-shipment/" + orderId + "/price",
    method: "get",
  });
};

OrdersService.save = function (data) {
  return fetch({
    url: "/order-shipment",
    method: "post",
    data: data,
  });
};

OrdersService.place = function (data) {
  return fetch({
    url: "/order-shipment/place",
    method: "post",
    data: data,
  });
};

OrdersService.orderDetail = function (id) {
  return fetch({
    url: "/shipping/order/" + id,
    method: "get",
  });
};

OrdersService.cancel = function (orderId) {
  return fetch({
    url: `/order-shipment/${orderId}/cancel`,
    method: "post",
  });
};

OrdersService.bulkUpload = function (data) {
  return fetch({
    url: `/csv/bulk-order-shipment`,
    method: "post",
    data,
  });
};

OrdersService.codRemittanceList = function (data) {
  return fetch({
    url: "/shipping/cod-remittance",
    method: "post",
    data,
  });
};

OrdersService.codRemittanceStatusUpdate = function (data) {
  return fetch({
    url: "/shipping/cod-remittance/update",
    method: "post",
    data,
  });
};

OrdersService.uploadProductImages = function (data) {
  return fetch({
    url: "/shipping/product-images",
    method: "post",
    data,
  });
};

export default OrdersService;
