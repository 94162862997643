import React, { useState, useEffect } from 'react';
import { Drawer, Form, Input, Button, Row, Col, Radio, message } from 'antd';
import { Link } from 'react-router-dom';
import rules from 'views/app-views/orders/ProductForm/rules';
import CalculatorService from 'services/CalculatorService'

const Calculator = props => {
    const [courierList, setCourierList] = useState([]);
    const [showCodAmount, setShowCodAmount] = useState(false);
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const onFinish = values => {
        CalculatorService.price(values).then(res => {
			if (res.isError) {
				message.error(res.data.message || 'Something went wrong')
			} else {
				setCourierList(res.data.rateList)
			}
		}).catch((err) => {
			message.error('Something went wrong')
		})
    };
    const onValuesChange = values => {
        if ('is_cod' in values) {
            setShowCodAmount(values.is_cod)
        }
    };
    useEffect(() => {
        if(props.visible){
            setVisible(true)
        }
        if(!visible && props.close){
            props.close()
        }
	}, [props, visible]);
    return (
        <Drawer
            className="shipping-calculator-drawer"
            width={window.innerWidth < 768 ? '100%' : 500}
            placement="right"
            onClose={() => setVisible(false)}
            closable={true}
            visible={visible}
            title="Shipping Calculator"
            headerStyle={{
                fontSize: '20px'
            }}
        >
            <Form
                layout='vertical'
                form={form}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                size="medium"
                initialValues={{
                    is_cod: false
                }}
            >
                <Row gutter={16}>
                    <Col xs={12} sm={24} md={8}>
                        <Form.Item
                            label="Pickup Pincode"
                            name="pickup_pincode"
                            rules={rules.pincode}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                        <Form.Item
                            label="Drop Pincode"
                            name="drop_pincode"
                            rules={rules.pincode}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                        <Form.Item name="weight" label="Weight (Kg)" rules={rules.weight}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={6}>
                        <Form.Item name="length" label="Length (Cm)" rules={rules.length}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={6}>
                        <Form.Item name="height" label="Height (Cm)" rules={rules.height}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={6}>
                        <Form.Item name="breadth" label="Breadth (Cm)" rules={rules.width}>
                            <Input />
                        </Form.Item>
                    </Col>
                    {showCodAmount &&
                        <Col xs={24} sm={24} md={6}>
                            <Form.Item name="cod_amount" label="COD Amount" rules={rules.cost}>
                                <Input />
                            </Form.Item>
                        </Col>
                    }
                    <Col xs={24} sm={24} md={18}>
                        <Form.Item name="is_cod">
                            <Radio.Group
                                className="text-center calculator-cod-radio"
                                buttonStyle="solid"
                            >
                                <Radio.Button value={false}>Prepaid</Radio.Button>
                                <Radio.Button value={true}>Cash on delivery</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                        <Form.Item className="mb-0 d-flex justify-content-end">
                            <Button type="primary" htmlType="submit">Calculate</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Row gutter={[24, 32]} className="pt-2 mt-2 calculator-partner-wrapper">
                {courierList.length > 0 && courierList.map((obj, key) =>
                    <Col xs={12} sm={24} md={8}>
                        <div className="calculator-partner-content-wrapper text-center">
                            <div>
                                <img className="calculator-partner-img" src={`${process.env.REACT_APP_CDN}/provider/${obj.courier}.png`} alt={obj.courier} onError={e => e.target.src = "/img/courierProvider/no-image.png"} />
                            </div>
                            <span className="calculator-partner-name">{obj.courier}</span>
                            <div><b>Rs. </b><b className="calculator-partner-price">{obj.total_charges}</b></div>
                            <Link to={'/orders/create'} onClick={() => setVisible(false)} ><Button type="primary" size="small">Place Order</Button></Link>
                        </div>
                    </Col>
                )}
            </Row>
        </Drawer>
    )
}
export default Calculator
