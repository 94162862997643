import {
    FETCH_ORDERS_LIST,
    ORDERS_LIST
} from '../constants/Orders';

export const fetchOrdersList = () => {
  return {
    type: FETCH_ORDERS_LIST
  }
};

export const ordersList = (ordersList) => {
  return {
    type: ORDERS_LIST,
    payload: ordersList
  }
};