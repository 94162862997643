import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect, useSelector } from 'react-redux'
import { Link } from "react-router-dom";

import {
  EditOutlined,
  LogoutOutlined,
  UserOutlined,
  SettingOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';

const menuItem = [
  /* 
    {
    title: "Account Setting",
    icon: SettingOutlined,
    path: "/"
    },
    {
    title: "Billing",
    icon: ShopOutlined ,
    path: "/"
  },
    {
    title: "Help Center",
    icon: QuestionCircleOutlined,
    path: "/"
  } */
  /* {
    title: "Settings",
    icon: SettingOutlined ,
    path: "/setting/edit-profile"
  }, */
  {
    title: "Change Password",
    icon: EditOutlined,
    path: "/setting/change-password"
  }
]

export const NavProfile = ({ signOut }) => {
  const { _id, name, user_type, email } = useSelector((state) => state.auth.userData)

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          {/* <Avatar size={45} icon={<UserOutlined />}  style={{
            backgroundColor: '#f37a20',
          }} /> */}
          <div /* className="pl-3" */>
            <h4 className="mb-0 text-capitalize text-truncate" style={{ display: 'block', width: '13rem' }}>{name}</h4>
            <span className="text-muted text-truncate" style={{ display: 'block', width: '13rem' }}>{email}</span>
            <span className="text-muted text-capitalize">{user_type}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <Link to={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </Link>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={e => signOut(_id)}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar icon={<UserOutlined />} style={{
            backgroundColor: '#f37a20',
          }} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null, { signOut })(NavProfile)
