import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'
import history from '../history'
import { AUTH_TOKEN, UPDATE_CREDIT, USER_DATA } from 'redux/constants/Auth'
import { notification } from 'antd';
import store from 'redux/store';

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
})

// Config
const ENTRY_ROUTE = '/login'
const TOKEN_PAYLOAD_KEY = 'authorization'
const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN)
	
  if (jwtToken) {
    config.headers[TOKEN_PAYLOAD_KEY] = 'JWT ' + jwtToken
  }

  if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
		history.push(ENTRY_ROUTE)
		window.location.reload();
  }

  return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
  Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	const state = store.getState();
	if(
		!response.data.isError && 
		response.data.data && 
		response.data.data.availableCredit !== undefined && 
		response.data.data.availableCredit !== state.auth.userData.credit
	){
		store.dispatch({
			type: UPDATE_CREDIT,
			payload: response.data.data.availableCredit
		})
	}
	return response.data
}, (error) => {

	let notificationParam = {
		message: ''
	}
	
	// Remove token and redirect 
	if (error.response.status === 401) {
		if(window.location.href.indexOf("verify/email") === -1){
			notificationParam.message = (error.response.data && error.response.data.message) || 'Something went wrong'
			notificationParam.description = 'Please login again'
			localStorage.removeItem(AUTH_TOKEN)
			localStorage.removeItem(USER_DATA)
			history.push(ENTRY_ROUTE)
			window.location.reload();
		}else{			
			notificationParam.message = `Something went wrong while registration.`
			notificationParam.description = `Please Register again.`
		}
	}

	if (error.response.status === 400 || error.response.status === 403) {
		notificationParam.message = (error.response.data && error.response.data.message) || 'Something went wrong'
	}

	if (error.response.status === 404) {
		notificationParam.message = (error.response.data && error.response.data.message) || 'Not Found'
	}

	if (error.response.status === 500) {
		notificationParam.message = 'Internal Server Error'
	}
	
	if (error.response.status === 508) {
		notificationParam.message = 'Time Out'
	}

	notification.error(notificationParam)

	return Promise.reject(error);
});

export default service