import {
  SettingOutlined,
  CodeSandboxOutlined,
  AppstoreOutlined,
  ApiOutlined,
  ProjectOutlined,
  ShoppingCartOutlined,
  WalletOutlined,
  FileTextOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: "sidenav.dashboard",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "orders-title",
    title: "sidenav.orders",
    icon: ShoppingCartOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "manage-order",
        path: `${APP_PREFIX_PATH}/orders/list`,
        title: "Manage Orders",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "manage-return",
        path: `${APP_PREFIX_PATH}/orders/return`,
        title: "Manage Return Orders",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "create-order",
        path: `${APP_PREFIX_PATH}/orders/create`,
        title: "Create New Order",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "manage-ndr",
    title: "Manage NDR",
    breadcrumb: false,
    icon: ProjectOutlined,
    submenu: [
      {
        key: "ndr-action-required",
        path: `${APP_PREFIX_PATH}/ndr/action-required`,
        title: "Action Required",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "ndr-action-requested",
        path: `${APP_PREFIX_PATH}/ndr/action-requested`,
        title: "Action Requested",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "ndr-delivered",
        path: `${APP_PREFIX_PATH}/ndr/delivered`,
        title: "Delivered",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "ndr-rto",
        path: `${APP_PREFIX_PATH}/ndr/rto`,
        title: "RTO",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "weightDiscrepancy",
    title: "Weight Discrepancy",
    breadcrumb: false,
    icon: CodeSandboxOutlined,
    submenu: [
      {
        key: "active-discrepancies",
        path: `${APP_PREFIX_PATH}/discrepancy/active`,
        title: "Active Discrepancies",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "disputes-discrepancies",
        path: `${APP_PREFIX_PATH}/discrepancy/disputes`,
        title: "Disputes Raised",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "closed-discrepancies",
        path: `${APP_PREFIX_PATH}/discrepancy/closed`,
        title: "Closed Discrepancies",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "transactions",
    path: `${APP_PREFIX_PATH}/transactions`,
    title: "sidenav.transactions",
    icon: WalletOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "cod-remittance",
    path: `${APP_PREFIX_PATH}/cod-remittance`,
    title: "COD Remittance",
    icon: FileTextOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "channelIntegration",
    path: `${APP_PREFIX_PATH}/channel-integration/list`,
    title: "sidenav.channelIntegration",
    icon: ApiOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "support",
    path: `${APP_PREFIX_PATH}/support`,
    title: "Support",
    icon: CommentOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "setting",
    path: `${APP_PREFIX_PATH}/setting/edit-profile`,
    title: "sidenav.setting",
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
