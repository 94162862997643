export const AUTH_TOKEN = 'auth_token'
export const USER_DATA = 'user_data'
export const SIGNIN = 'SIGNIN'; 
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE'
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = 'SIGNIN_WITH_GOOGLE_AUTHENTICATED'
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK'
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED = 'SIGNIN_WITH_FACEBOOK_AUTHENTICATED'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const UPDATE_CREDIT = 'UPDATE_CREDIT'
export const ADD_ADDRESS = 'ADD_ADDRESS'
export const EDIT_PROFILE = 'EDIT_PROFILE'
export const BANK_DETAILS = 'BANK_DETAILS'
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'