import {
	AUTH_TOKEN,
	AUTHENTICATED,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
	HIDE_LOADING,
	SIGNIN_WITH_GOOGLE_AUTHENTICATED,
	SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
	USER_DATA,
	CHANGE_PASSWORD,
	UPDATE_CREDIT,
	ADD_ADDRESS,
	EDIT_PROFILE,
	BANK_DETAILS,
	UPDATE_USER_DATA
} from '../constants/Auth';

const initState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN),
	userData: JSON.parse(localStorage.getItem(USER_DATA))
}

const auth = (state = initState, action) => {
	let userData = { ...state.userData };
	switch (action.type) {
		case AUTHENTICATED:
			return {
				...state,
				loading: false,
				redirect: '/',
				token: action.token,
				userData: action.userData
			}
		case ADD_ADDRESS:
			userData.address = action.payload.address
			let pickupAddress = action.payload.address.find(o => o.address_type === 'pickup' && o.is_default);
			if(pickupAddress && pickupAddress._id){
				userData.pickup_address_id = pickupAddress._id;
			}
			localStorage.setItem(USER_DATA, JSON.stringify(userData));
			return {
				...state,
				userData
			}
		case UPDATE_CREDIT:
			userData.credit = action.payload
			localStorage.setItem(USER_DATA, JSON.stringify(userData));
			return {
				...state,
				userData
			}
		case SHOW_AUTH_MESSAGE:
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}
		case HIDE_AUTH_MESSAGE:
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SIGNOUT_SUCCESS: {
			return {
				...state,
				token: null,
				redirect: '/',
				loading: false
			}
		}
		case SIGNUP_SUCCESS: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		case CHANGE_PASSWORD: {
			if(userData.is_password_not_exist) {
				delete userData.is_password_not_exist
				localStorage.setItem(USER_DATA, JSON.stringify(userData));
				return {
					...state,
					userData
				}
			}
			return {
				...state,
				loading: false
			}
		}
		case EDIT_PROFILE: {
			userData = { ...userData, ...action.payload }
			localStorage.setItem(USER_DATA, JSON.stringify(userData));
			return {
				...state,
				userData
			}
		}
		case BANK_DETAILS: {
			userData = { ...userData, ...action.payload }
			localStorage.setItem(USER_DATA, JSON.stringify(userData));
			return {
				...state,
				userData
			}
		}
		case UPDATE_USER_DATA: {
			userData = { ...userData, ...action.payload }
			localStorage.setItem(USER_DATA, JSON.stringify(userData));
			return {
				...state,
				userData
			}
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
		case HIDE_LOADING: {
			return {
				...state,
				loading: false
			}
		}
		case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		default:
			return state;
	}
}

export default auth