import {
	ORDERS_LIST
} from '../constants/Orders';

const initState = {
  ordersList: []
}

const orders = (state = initState, action) => {
    switch (action.type) {
        case ORDERS_LIST:
			return {
				...state,
				ordersList: action.payload
			}
		default:
			return state;
	}
}

export default orders