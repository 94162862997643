import {
    FETCH_TRANSACTIONS_LIST,
    TRANSACTIONS_LIST
} from '../constants/Transactions';

export const fetchTransactionsList = () => {
  return {
    type: FETCH_TRANSACTIONS_LIST
  }
};

export const transactionsList = (transactionsList) => {
  return {
    type: TRANSACTIONS_LIST,
    payload: transactionsList
  }
};