import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Input, message, Button } from "antd";
import NumberFormat from "react-number-format";
import CreditService from "services/CreditService";
import DataDisplayWidget from "components/shared-components/DataDisplayWidget";
import { WalletFilled, ThunderboltOutlined } from "@ant-design/icons";

const CreditPopup = (props) => {
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const isSmallScreen = window.screen.width < 768;
  const { credit, name, email, phone, hold_amount } = useSelector(
    (state) => state.auth.userData
  );

  // const user = useSelector((state) => state.auth.userData);

  const payWithRazor = (order_id, amount) => {
    const options = {
      key: process.env.REACT_APP_RZAORPAY_KEY, //"rzp_live_vbO010w8kEJo75", //'rzp_test_xRjYTO1jxwOmDj',
      amount: amount * 100, // amount should be in paise format to display Rs 1255 without decimal point
      currency: "INR",
      name: "Parceldeck Pvt Ltd", // company name or product name
      description: "Session will end in 15 minutes", // product description
      image: "/img/logo-sm.svg",
      order_id: order_id,
      modal: {
        escape: false,
      },
      notes: {},
      prefill: {
        name,
        email,
        contact: phone,
      },
      theme: {
        color: "#0c238a",
      },
    };
    options.handler = (response, error) => {
      options.response = response;
      console.log(response);
      console.log(options);
      verifySignature(response, amount);
    };
    options.modal.ondismiss = () => {
      console.log("Transaction cancelled.");
    };
    const rzp = window.Razorpay(options);

    rzp.on("payment.failed", function (response) {
      console.log("response.error.code ==> ", response.error.code);
      console.log(
        "response.error.description ==> ",
        response.error.description
      );
      console.log("response.error.source ==> ", response.error.source);
      console.log("response.error.step ==> ", response.error.step);
      console.log("response.error.reason ==> ", response.error.reason);
      console.log(
        "response.error.metadata.order_id ==> ",
        response.error.metadata.order_id
      );
      console.log(
        "response.error.metadata.payment_id ==> ",
        response.error.metadata.payment_id
      );
    });
    rzp.open();
  };

  const verifySignature = (response) => {
    CreditService.verifySignature(response)
      .then((res) => {
        if (!res.isError) {
          setVisible(false);
          if (props.onAddCreditSuccess) {
            props.onAddCreditSuccess(res.data.availableCredit);
          }
          message.success("Credits added");
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(`err ======--======> `, err);
        message.error("Something went wrong");
      });
  };

  const openRazorPay = (amount) => {
    CreditService.openRazorPay({ order_amount: amount * 100 })
      .then((res) => {
        if (!res.isError) {
          payWithRazor(res.data.id, amount);
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(`err ======--======> `, err);
        message.error("Something went wrong");
      });
  };

  useEffect(() => {
    if (props.visible) {
      if (props.amount) {
        form.setFieldsValue({ amount: props.amount });
      }
      setVisible(true);
    }
    if (!visible && props.close) {
      props.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, visible]);

  const formatNumber = (num) => {
    return (
      <NumberFormat
        displayType={"text"}
        value={Math.round(num * 100) / 100}
        prefix={"₹"}
        thousandSeparator={true}
      />
    );
  };

  return (
    <>
      {props.isHeaderNav && (
        <div
          className="d-flex"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setVisible(true);
          }}
        >
          <WalletFilled
            size={"large"}
            className="mt-3 header-wallet-icon"
            style={{ fontSize: "40px", color: "#000" }}
          />
          <div
            className="text-center mt-3 font-weight-semibold"
            style={{ color: "#000" }}
          >
            <div className="font-size-lg">{formatNumber(credit)}</div>
            <span className="font-size-sm ml-2">Wallet Balance</span>
          </div>
          {!props.isMobile && (
            <Button
              type="primary"
              className="mt-3 ml-3 header-recharge-btn"
              hoverable={"true"}
              icon={<ThunderboltOutlined />}
            >
              Recharge
            </Button>
          )}
        </div>
      )}
      {props.isDashboard && (
        <span
          onClick={() => {
            setVisible(true);
          }}
        >
          <DataDisplayWidget
            value={
              <div>
                <div>
                  <p
                    className="my-0"
                    style={{
                      fontSize: "15px",
                      color: "black",
                      fontWeight: "normal",
                    }}
                  >
                    Available Bal
                  </p>
                  <p
                    className="my-0"
                    style={{ fontSize: "18px", color: "black" }}
                  >
                    {formatNumber(credit + (hold_amount || 0))}
                  </p>
                </div>
                <div
                  className={`${isSmallScreen ? "d-flex-col" : "d-flex"} mt-3`}
                >
                  <div
                    className={`pr-3 ${isSmallScreen ? "d-none" : ""}`}
                    style={{ borderRight: `2px solid #959595` }}
                  >
                    <p
                      className="my-0"
                      style={{ fontSize: "16px", color: "black" }}
                    >
                      {formatNumber(credit)}
                    </p>
                    <p
                      className="my-0"
                      style={{
                        fontSize: "14px",
                        color: "black",
                        fontWeight: "normal",
                      }}
                    >
                      Usable Balance
                    </p>
                  </div>
                  <div className={isSmallScreen ? "" : "pl-3"}>
                    <p
                      className="my-0"
                      style={{ fontSize: "16px", color: "red" }}
                    >
                      {formatNumber(hold_amount || 0)}
                    </p>
                    <p
                      className="my-0"
                      style={{
                        fontSize: "14px",
                        color: "black",
                        fontWeight: "normal",
                      }}
                    >
                      Hold Balance
                    </p>
                  </div>
                </div>
              </div>
            }
          />
        </span>
      )}
      <Modal
        visible={visible}
        title="Enter credit amount"
        okText="Proceed to Pay"
        cancelText="Cancel"
        onCancel={() => {
          setVisible(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              if (values.amount < 200) {
                message.error("Minimum amount should be 200");
              } else {
                openRazorPay(values.amount);
              }
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        width="300px"
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            name="amount"
            label="Amount"
            rules={[
              {
                required: true,
                message: "Please enter your amount!",
              },
            ]}
          >
            <Input addonBefore={"₹"} maxLength="7" placeholder="Minimum Recharge ₹200"/>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreditPopup;
