import fetch from 'auth/FetchInterceptor'

const CalculatorService = {}

CalculatorService.price = function (params) {
  return fetch({
    url: '/order-shipment/pricing',
    method: 'get',
    params
  })
}

export default CalculatorService