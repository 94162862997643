import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	FETCH_TRANSACTIONS_LIST
} from '../constants/Transactions';
import {
	transactionsList
} from "../actions/Transactions";
import {
	showAuthMessage
} from "../actions/Auth";
import TransactionsService from 'services/TransactionsService'

export function* fetchTransactionsList() {
	yield takeEvery(FETCH_TRANSACTIONS_LIST, function* () {
		try {
			const response = yield call(TransactionsService.transactionsList);
			if (response.isError) {
				yield put(showAuthMessage(response.message || 'Something went wrong'));
			} else {
				yield put(transactionsList(response.data));
			}
		} catch (err) {
			//yield put(showAuthMessage(err));
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(transactionsList),
	]);
}