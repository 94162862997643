import fetch from 'auth/FetchInterceptor'

const CreditService = {}

CreditService.verifySignature = function (data) {
  return fetch({
    url: '/user/wallet/signaturerzp',
    method: 'post',
    data: data
  })
}

CreditService.openRazorPay = function (data) {
  return fetch({
    url: '/user/wallet/createRZPOrder',
    method: 'post',
    data: data
  })
}

export default CreditService